import React from "react";
import { IonToast } from "@ionic/react";
import "./ConfirmDeleteToast.css"

type ConfirmDeleteToastProps = {
  isOpen: boolean;
  message: string;
  onCancel: () => void;
  onConfirm: () => void;
};

const ConfirmDeleteToast: React.FC<ConfirmDeleteToastProps> = ({
  isOpen,
  message,
  onCancel,
  onConfirm,
}) => {
  return (
    <IonToast
      isOpen={isOpen}
      onDidDismiss={onCancel}
      layout="stacked"
      position="middle"
      cssClass="delete-toast"
      message={message}
      duration={0} // Mantiene el toast visible hasta interacción
      buttons={[
        {
          text: "Cancelar",
          role: "cancel",
          handler: onCancel,
        },
        {
          text: "Eliminar",
          role: "destructive",
          handler: onConfirm
        },
      ]}
    />
  );
};

export default ConfirmDeleteToast;
