import React, { useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonButton,
} from "@ionic/react";
import { useHistory, useLocation } from "react-router-dom";
import "./GaleriaFotos.css";
import { ImagenObra } from "../../store/ducks/observaciones/types";

type GaleriaFotosProps = {
  imagenes: ImagenObra[];
};

const GaleriaFotos: React.FC<GaleriaFotosProps> = ({ imagenes }) => {

  const [showAllImages, setShowAllImages] = useState(false);
  const history = useHistory();
  const location = useLocation();

  // Open the modal and push a new route
  const openModal = (imgUrl: string, comentario: string) => {
    history.push({
      pathname: location.pathname,
      search: `?modalOpened=true`, // Incluir imgUrl en la query
      state: { comentario, imgUrl }, // Pasar comentario en el state
    });
  };

  // Toggle between limited and full image views
  const handleToggleImages = () => {
    setShowAllImages(!showAllImages);
  };

  // Limit the number of images shown initially
  const visibleImages = showAllImages ? imagenes : imagenes.slice(0, 4);

  return (
    <div className="gallery-box">
      <IonGrid style={{ padding: 0, margin: 0 }}>
        <IonRow style={{ padding: 0, margin: 0 }}>
          {visibleImages.map((imagen) => (
            <IonCol key={imagen._id} size="6" size-md="4" size-lg="3">
              <div style={{ overflow: "hidden", borderRadius: "15px" }}>
                <IonImg
                  src={imagen.imgUrl}
                  onClick={() => openModal(imagen.imgUrl, imagen.comentario || "Sin comentario")}
                  className="img-galeria-observacion"
                />
              </div>
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>

      {imagenes.length > 4 && (
        <div className= "d-flex justify-content-center mt-2">
          <IonButton
            className="rounded-button"
            fill="outline"
            onClick={handleToggleImages}
          >
            {showAllImages ? "Ver menos imágenes" : "Ver más imágenes"}
          </IonButton>
        </div>
      )}
    </div>
  );
};

export default GaleriaFotos;
