import axios from "axios";

const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BASE_URL_DEVELOPMENT : process.env.REACT_APP_BASE_URL_PRODUCTION;

const avisoInstance = axios.create({
    baseURL: baseURL,
});

export const requestPostAvisos = (idEdificio: any, aviso: FormData) =>
    avisoInstance.request({
        method: "POST",
        data: aviso,
        url: `/api/avisos/${idEdificio}`,
    });

export const requestGetAvisosByEdificio = (idEdificio: any) =>
    avisoInstance.request({
        method: "GET",
        url: `/api/avisos/${idEdificio}`,
    });