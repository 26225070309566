import React, { useState } from "react";
import {
    IonModal,
    IonButton,
    IonIcon,
    IonContent,
    IonPopover,
    isPlatform,
} from "@ionic/react";
import { closeOutline, chatboxEllipsesOutline } from "ionicons/icons";
import "./ModalImagen.css";
import { useHistory, useLocation } from "react-router";



const ModalImagen: React.FC = () => {
    const location = useLocation();
    const history = useHistory();

    // Obtener comentario desde el estado
    const state = location.state as { comentario: string, imgUrl: string };
    const comentario = state?.comentario || "Sin comentario";
    const imgUrl = state?.imgUrl || "#";

    const closeModal = () => {
        history.replace({
            pathname: location.pathname, // Restablece la ruta sin parámetros
            search: "", // Limpia los parámetros de búsqueda
        });
    };

    // Accede a los datos del state

    const [popoverOpen, setPopoverOpen] = useState(false);
    const [touchScale, setTouchScale] = useState(1);
    const [initialDistance, setInitialDistance] = useState<number | null>(null);
    const [initialOrigin, setInitialOrigin] = useState<{ x: number; y: number } | null>(null);


    const isMobileDevice = () => {
        const isIonicMobile = isPlatform("mobile");
        const isUserAgentMobile = /Mobi|Android|iPhone|iPad/i.test(navigator.userAgent);
        return isIonicMobile && isUserAgentMobile;
    };

    let lastTapTime = 0; // Para manejar el tiempo entre taps


    const handleDoubleTap = (e: React.TouchEvent<HTMLDivElement>) => {
        if (e.touches.length > 1) return; // Ignorar si hay más de un toque

        const currentTime = new Date().getTime();
        const tapGap = currentTime - lastTapTime;

        const container = e.currentTarget;
        const img = container.querySelector("img") as HTMLImageElement;
        if (!img) return;

        // Obtén el nivel de zoom actual
        let scale = parseFloat(img.getAttribute("data-scale") || "1");

        if (tapGap < 300 && tapGap > 0) {
            // Doble tap detectado
            if (scale > 1) {
                // Si el zoom es mayor a 1x, siempre vuelve al tamaño original
                scale = 1;
                img.style.transformOrigin = "center center"; // Restablecer el origen al centro
                img.style.transform = `scale(${scale})`;
            } else {
                // Si está en 1x, realiza zoom a 2x
                scale = 2;
                const rect = container.getBoundingClientRect();
                const touch = e.touches[0];
                const offsetX = ((touch.clientX - rect.left) / rect.width) * 100;
                const offsetY = ((touch.clientY - rect.top) / rect.height) * 100;

                img.style.transformOrigin = `${offsetX}% ${offsetY}%`; // Establece el origen del zoom
                img.style.transform = `scale(${scale})`;
            }

            // Actualiza el estado del zoom
            img.setAttribute("data-scale", scale.toString());
            setTouchScale(scale); // Asegura que el estado se sincronice
        }

        lastTapTime = currentTime; // Actualiza el tiempo del último tap
    };

    const handlePinchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        if (e.touches.length === 2) {
            const touch1 = e.touches[0];
            const touch2 = e.touches[1];
            const deltaX = touch2.clientX - touch1.clientX;
            const deltaY = touch2.clientY - touch1.clientY;
            const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
            setInitialDistance(distance);

            const rect = e.currentTarget.getBoundingClientRect();
            const centerX = (((touch1.clientX + touch2.clientX) / 2 - rect.left) / rect.width) * 100;
            const centerY = (((touch1.clientY + touch2.clientY) / 2 - rect.top) / rect.height) * 100;
            setInitialOrigin({ x: centerX, y: centerY });
        }
    };

    const handlePinchMove = (e: React.TouchEvent<HTMLDivElement>) => {
        if (e.touches.length === 2 && initialDistance !== null && initialOrigin !== null) {
            const container = e.currentTarget;
            const img = container.querySelector("img") as HTMLImageElement;
            if (!img) return;

            const touch1 = e.touches[0];
            const touch2 = e.touches[1];
            const deltaX = touch2.clientX - touch1.clientX;
            const deltaY = touch2.clientY - touch1.clientY;
            const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

            let scale = touchScale * (distance / initialDistance);
            scale = Math.min(Math.max(1, scale), 4); // Limita la escala entre 1 y 4

            if (scale === 1) {
                img.style.transformOrigin = "center center"; // Restablecer transformOrigin si el zoom vuelve a 1x
            }

            img.style.transform = `scale(${scale})`;
            img.style.transformOrigin = `${initialOrigin.x}% ${initialOrigin.y}%`;

            img.setAttribute("data-scale", scale.toString());
            setTouchScale(scale); // Actualiza el estado de React
        }
    };

    const handlePinchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
        if (e.touches.length === 0) {
            setInitialDistance(null);
            setInitialOrigin(null);
        }
    };

    const handleTouchStartCombined = (e: React.TouchEvent<HTMLDivElement>) => {
        if (e.touches.length === 1) {
            handleDoubleTap(e);
        }
        handlePinchStart(e);
    };
    const handleZoom = (e: React.WheelEvent<HTMLDivElement>) => {
        if (isMobileDevice()) {
            return;
        }
        const container = e.currentTarget;
        const img = container.querySelector("img") as HTMLImageElement;
        if (!img) return;

        let scale = parseFloat(img.getAttribute("data-scale") || "1");
        const newScale = scale + e.deltaY * -0.01; // Ajusta la velocidad del zoom

        // Bloquear modificaciones si ya está en los límites
        if ((scale === 1 && newScale < 1) || (scale === 4 && newScale > 4)) {
            return; // No hacer nada si estamos en el límite
        }

        // Limita la escala entre 1 y 4
        scale = Math.min(Math.max(1, newScale), 4);

        // Calcula la posición del cursor en el contenedor solo si no estamos en los límites
        if (scale > 1 && scale < 4) {
            const rect = container.getBoundingClientRect();
            const offsetX = ((e.clientX - rect.left) / rect.width) * 100;
            const offsetY = ((e.clientY - rect.top) / rect.height) * 100;

            // Aplica el zoom centrado en el cursor
            img.style.transformOrigin = `${offsetX}% ${offsetY}%`;
        }

        // Aplica la transformación de escala
        img.style.transform = `scale(${scale})`;
        img.setAttribute("data-scale", scale.toString());
        setTouchScale(scale);
    };
    const handleDoubleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (isMobileDevice()) {
            return;
        }
        const container = e.currentTarget;
        const img = container.querySelector("img") as HTMLImageElement;
        if (!img) return;

        let scale = parseFloat(img.getAttribute("data-scale") || "1");

        if (scale === 1) {
            scale = 2;

            const rect = container.getBoundingClientRect();
            const offsetX = ((e.clientX - rect.left) / rect.width) * 100;
            const offsetY = ((e.clientY - rect.top) / rect.height) * 100;

            img.style.transformOrigin = `${offsetX}% ${offsetY}%`;
            img.style.transform = `scale(${scale})`;
        } else {
            scale = 1;
            img.style.transform = `scale(${scale})`;
            img.style.transformOrigin = "center center";
        }

        img.setAttribute("data-scale", scale.toString());
        setTouchScale(scale);
    };

    return (
        <IonModal isOpen={true} onDidDismiss={closeModal} className="img-gallery-modal">
            <div
                className="image-modal-container-gallery"
                onTouchMove={handlePinchMove}
                onTouchStart={handleTouchStartCombined}
                onTouchEnd={handlePinchEnd}
                onWheel={handleZoom}
                onDoubleClick={handleDoubleClick}
            >
                <IonButton
                    className="close-image-button-gallery"
                    fill="clear"
                    onClick={closeModal}
                >
                    <IonIcon icon={closeOutline} size="large" />
                </IonButton>

                {imgUrl && (
                    <img
                        src={imgUrl}
                        alt="Imagen seleccionada"
                        className="modal-img"
                        data-scale="1"
                        draggable={false}
                    />
                )}

                <IonButton
                    fill="clear"
                    onClick={() => setPopoverOpen(true)}
                    className="comentario-open-button-gallery"
                >
                    <IonIcon icon={chatboxEllipsesOutline} className="footer-icon" />
                </IonButton>

                <IonPopover
                    isOpen={popoverOpen}
                    onDidDismiss={() => setPopoverOpen(false)}
                    className="comment-pop-over"
                >
                    <IonContent className="ion-padding">
                        <span className="font-bold comentario-label">Comentario:</span>
                        {comentario}
                    </IonContent>
                </IonPopover>
            </div>
        </IonModal>
    );
};

export default ModalImagen;
