import axios from "axios";

const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BASE_URL_DEVELOPMENT : process.env.REACT_APP_BASE_URL_PRODUCTION;

const reporteInstance = axios.create({
    baseURL: baseURL,
});

export const requestPostReporte = (idEdificio: any, reporte: FormData) =>
    reporteInstance.request({
        method: "POST",
        data: reporte,
        url: `/api/reportes-desperfectos/${idEdificio}`,
    });

export const requestGetReportesByEdificio = (idEdificio: any) =>
    reporteInstance.request({
        method: "GET",
        url: `/api/reportes-desperfectos/${idEdificio}`,
    });
export const requestGetReporteById = (idReporte: any) =>
    reporteInstance.request({
        method: "GET",
        url: `/api/reportes-desperfectos/reporte/${idReporte}`,
    });
export const requestDeleteReporteById = (idReporte: any) =>
    reporteInstance.request({
        method: "PATCH",
        url: `/api/reportes-desperfectos/reporte/${idReporte}`,
    });