import React from "react";
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
} from "@ionic/react";
import "../../styles/App.css";


const PoliticaPrivacidad: React.FC = () => {
    return (
        <IonPage>
            <IonHeader className="ion-no-border">
                <IonToolbar className="services-toolbar">
                    {/* Logo */}
                    <div className="ion-logo-servicios" slot="start"></div>

                    {/* Botón de Login */}
                    <IonButton slot="end" shape="round" fill="outline" size="small" className="login-toolbar-btn" routerLink="/">
                        Login
                    </IonButton>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen className="ion-content-grey">
                <IonGrid className="pb-50 pt-50">

                    
                    <IonRow className="dashboard-row">

                        <IonCol size="12" size-lg="8" offset-lg="2">
                            <header className="pb-30">
                                <h1 className="font-22 text-uppercase">Política de Privacidad</h1>
                                <p className="font-14 text-uppercase"><strong>Actualizado el 27 de diciembre de 2024</strong></p>
                            </header>

                            <section>
                            <h2 className="font-16 text-uppercase">Introducción</h2>
                            <p className="font-14">
                                En Obrabit, respetamos tu privacidad y nos comprometemos a proteger la información que compartes con nosotros. Esta Política de Privacidad explica qué datos recopilamos, cómo los utilizamos, cómo se maneja la información compartida por los usuarios, y cuáles son tus derechos al respecto.
                                </p>
                              
                            </section>
                            <section>
                                <h2 className="font-16 text-uppercase">Información que recopilamos</h2>
                                <p className="font-14">
                                    Cuando usas nuestra aplicación, podemos recopilar la siguiente información:
                                </p>
                                <ul className="no-indent">
                                    <li className="font-14"><strong>Información de cuenta:</strong> Los usuarios que se asignan a cada copropietario son creados por nuestro equipo y están asociados a la unidad correspondiente. Estas cuentas incluyen un nombre de usuario ficticio y una contraseña inicial, que los usuarios pueden cambiar posteriormente.</li>
                                    <li className="font-14"><strong>Información personal (opcional):</strong> Los datos como nombre, dirección de correo electrónico u otra información personal pueden ser completados voluntariamente por los usuarios.</li>
                                    <li className="font-14"><strong>Información de uso:</strong> Datos relacionados con tu actividad dentro de la aplicación, como interacciones con la plataforma, reportes de desperfectos y servicios, entre otros.</li>
                                </ul>
                            </section>

                            <section>
                                <h2 className="font-16 text-uppercase">Cómo usamos tu información</h2>
                                <p className="font-14">Utilizamos la información recopilada para:</p>
                                <ul className="no-indent">
                                    <li className="font-14">Ofrecerte los servicios y funcionalidades de la aplicación.</li>
                                    <li className="font-14">Mejorar tu experiencia como usuario.</li>
                                    <li className="font-14">Enviar notificaciones relacionadas con actualizaciones o problemas técnicos (si es necesario).</li>
                                </ul>
                            </section>

                            <section>
                                <h2 className="font-16 text-uppercase">Contenido compartido y responsabilidades</h2>
                                <p className="font-14">
                                    La aplicación permite a los copropietarios y a la administración del edificio subir y compartir información adicional, como:
                                </p>
                                <ul className="no-indent">
                                    <li className="font-14">Comprobantes de pagos relacionados con el mantenimiento.</li>
                                    <li className="font-14">Avisos generales.</li>
                                    <li className="font-14">Reportes de desperfectos o problemas edilicios.</li>
                                    <li className="font-14">Documentos e información administrativa.</li>
                                </ul>
                                <p className="font-14">
                                    <strong>Responsabilidad del contenido:</strong>
                                </p>
                                <ul className="no-indent">
                                    <li className="font-14"><strong>Obrabit</strong> no valida ni se hace responsable del contenido que suben los usuarios, como los copropietarios o la administración del edificio. Los usuarios son responsables de la veracidad, precisión y legalidad de la información que proporcionan.</li>
                                    <li className="font-14">
                                        El equipo técnico de <strong>[Nombre de tu aplicación]</strong> es responsable únicamente de la información técnica que publica, como los informes técnicos, memorias constructivas, presupuestos y seguimiento de obras. No nos hacemos responsables de la información subida por los copropietarios o la administración del edificio.
                                    </li>
                                </ul>
                            </section>

                            <section>
                                <h2 className="font-16 text-uppercase">Compartir tu información</h2>
                                <p className="font-14">No compartimos tu información personal con terceros, excepto cuando sea necesario para:</p>
                                <ul className="no-indent">
                                    <li className="font-14">Cumplir con requerimientos legales.</li>
                                    <li className="font-14">Proteger los derechos o la seguridad de los usuarios o nuestra plataforma.</li>
                                </ul>
                                <p className="font-14">
                                    En el caso de los documentos e información proporcionada por los copropietarios y la administración, esta se compartirá únicamente dentro de la plataforma con otros usuarios con los permisos correspondientes.
                                </p>
                            </section>

                            <section>
                                <h2 className="font-16 text-uppercase">Seguridad de tus datos</h2>
                                <p className="font-14">
                                    Nos comprometemos a proteger tu información utilizando medidas técnicas y organizativas razonables. Sin embargo, ten en cuenta que ninguna transmisión de datos en Internet es 100% segura. Hacemos todo lo posible para proteger tu información personal, pero no podemos garantizar su seguridad absoluta.
                                </p>
                            </section>

                            <section>
                                <h2 className="font-16 text-uppercase">Tus derechos</h2>
                                <p className="font-14">Tienes derecho a:</p>
                                <ul className="no-indent">
                                    <li className="font-14">Acceder, modificar o eliminar los datos personales que has proporcionado.</li>
                                    <li className="font-14">Solicitar información sobre el manejo de tus datos y la información compartida dentro de la plataforma.</li>
                                    <li className="font-14">Contactarnos para cualquier pregunta o solicitud relacionada con tus datos personales.</li>
                                </ul>
                            </section>

                            <section>
                                <h2 className="font-16 text-uppercase">Cambios en esta política</h2>
                                <p className="font-14">
                                    Podemos actualizar esta Política de Privacidad de vez en cuando. En caso de que realicemos cambios importantes, te notificaremos publicando la nueva versión en nuestra aplicación. Te recomendamos revisar periódicamente esta política para mantenerte informado sobre cómo protegemos tu privacidad.
                                </p>
                            </section>
                        </IonCol>
                    </IonRow>
                 


                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default PoliticaPrivacidad;
