import React, { useEffect, useState } from "react";
import {
  IonCard,
  IonCardContent,
  IonButton,
  IonIcon,
} from "@ionic/react";
import { ellipsisVertical } from "ionicons/icons";
import moment from "moment";
import "moment/locale/es";
import { IReporte } from "../../../store/ducks/reportes/types";
import OptionsPopover from "../../Popovers/OptionsPopover";
import { FiCalendar } from "react-icons/fi";
import "./ReporteListItem.css";
import { useHistory } from "react-router";
import ConfirmDeleteToast from "../../Toasts/ConfirmDeleteToast";

type ReporteListItemProps = {
  link: string;
  reporte: IReporte;
  imgUrl?: string;
  alt?: string;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
};

const ReporteListItem: React.FC<ReporteListItemProps> = ({
  link,
  reporte,
  imgUrl,
  alt,
  onEdit,
  onDelete,
}) => {
  const [showPopover, setShowPopover] = useState(false);
  const [popoverEvent, setPopoverEvent] = useState<MouseEvent | undefined>(
    undefined
  );
  const [showDeleteToast, setShowDeleteToast] = useState(false);
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen(() => {
      if (showPopover) {
        setShowPopover(false); // Cierra el popover al navegar hacia atrás
      }
    });
    return () => {
      unlisten(); // Limpia el listener al desmontar el componente
    };
  }, [history, showPopover]);
  return (
    <>
      <IonCard
        className="ion-card-list"
        onClick={() => {
          history.push(link);
        }}
      >
        <div className="justify-content-between d-flex align-items-center list-card-toolbar mt-1">
          <h4 className="m-0 font-dark-grey font-weight-500 font-18 text-capitalize">
            {reporte?.titulo || "Desperfecto sin título"}
          </h4>
          <IonButton
            fill="clear"
            shape="round"
            className="card-ellipsis-btn"
            onClick={(e) => {
              e.stopPropagation();
              setPopoverEvent(e.nativeEvent as MouseEvent);
              setShowPopover(true);
            }}
          >
            <IonIcon icon={ellipsisVertical}></IonIcon>
          </IonButton>
        </div>

        <div className="clickable-area" style={{ cursor: "pointer" }}>
          <div className="img-card-box">
            <img
              alt={alt || "Imagen del reporte"}
              src={imgUrl || "/assets/placeholder.png"}
              className="img-card-list"
            />
          </div>
          <IonCardContent className="ion-card-list-content">
            <div className="mb-2 d-flex align-items-center text-small">
              <span>
                <FiCalendar className="mr-1" />
                {reporte?.createdAt
                  ? moment(reporte.createdAt).locale("es").format("LL")
                  : "Sin fecha"}
              </span>
            </div>
            <div className="mb-1 justify-content-between d-flex">
              <p className="text-capitalize font-weight-500">Sector</p>
              <p className="text-capitalize">{reporte?.sector || "Otro"}</p>
            </div>
            <div className="mb-1 justify-content-between d-flex">
              <p className="text-capitalize font-weight-500">Categoría</p>
              <p className="text-capitalize">{reporte?.tipoDesperfecto || "Otro"}</p>
            </div>
            <div className="mb-1 justify-content-between d-flex">
              <p className="text-capitalize font-weight-500">Estado</p>
              <p className="text-capitalize">{reporte?.estado || "Sin estado"}</p>
            </div>
            <div className="mb-1 justify-content-between d-flex">
              <p className="text-capitalize font-weight-500">Visto por Administración</p>
              <p className="text-capitalize">{reporte?.vistoPorAdministracion ? "Si" : "No"}</p>
            </div>
          </IonCardContent>
        </div>
        {/* Reutilizamos OptionsPopover */}
        {/* Reutilizamos OptionsPopover */}
        <OptionsPopover
          isOpen={showPopover}
          event={popoverEvent}
          onDismiss={() => setShowPopover(false)}
          onEdit={() => {
            onEdit(reporte._id);
            setShowPopover(false);
          }}
          onDelete={() => {
            setShowPopover(false);
            setShowDeleteToast(true); // Muestra el toast al hacer clic en eliminar
          }}
        />


      </IonCard>

      <ConfirmDeleteToast
        isOpen={showDeleteToast}
        message="¿Estás seguro que deseas enviar el reporte a la papelera? En 30 días será eliminado definitivamente."
        onCancel={() => setShowDeleteToast(false)}
        onConfirm={() => {
          onDelete(reporte._id);
          setShowDeleteToast(false);
        }}
      />
    </>
  );
};

export default React.memo(ReporteListItem);
