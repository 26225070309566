import {
    IonBackButton,
    IonButtons,
    IonCol,
    IonContent,
    IonFab,
    IonFabButton,
    IonGrid,
    IonHeader,
    IonIcon,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
    useIonViewDidEnter,
    useIonViewDidLeave,
    useIonViewWillEnter,
    useIonViewWillLeave,
} from '@ionic/react';
import "../../styles/App.css";
import "./ReportesCopList.css";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/ducks/rootReducer';
import React, { useEffect } from 'react';
import { StatusBar, Style } from '@capacitor/status-bar';
import ToolbarMenu from '../../components/Toolbars/ToolbarMenu';
import { add } from 'ionicons/icons';
import { RouteComponentProps } from 'react-router';
import { clearMessage, deleteReporteRequest, getReportesByEdificio } from '../../store/ducks/reportes/actions';
import CustomSpinner from '../../components/Spinner/CustomSpinner';
import ReporteListItem from '../../components/Copropietario/ReportesDesperfectos/ReporteListItem';
import { IReporte } from '../../store/ducks/reportes/types';
import { ReportesScopes } from '../../utils/reportesScope';

type Params = { idEdificio: string };

const ReportesCopList: React.FC<RouteComponentProps<Params>> = ({
    match: {
        params: { idEdificio },
    },
}) => {
    const dispatch = useDispatch();
    const { reportesByEdificio, reportesLoading } = useSelector((state: RootState) => state.reportes);
   
    useIonViewWillEnter(() => {
        dispatch(clearMessage());
        dispatch(getReportesByEdificio(idEdificio));
    });


    const handleEdit = (id: string) => {
        console.log("Editar reporte con ID:", id);

    };

    const handleDelete = (id: string) => {
        dispatch(deleteReporteRequest(id, ReportesScopes.LISTA_REPORTES)); // Llama la acción de Redux para eliminar

    };
    return (
        <IonPage>
            <IonHeader className="ion-no-border border-header">
                <ToolbarMenu title="Reportes de desperfectos" />
            </IonHeader>
            <IonContent fullscreen className="ion-content-grey">
                {(reportesLoading) ? (
                    <div className="center-content">
                        <CustomSpinner color="primary" />
                    </div>

                ) : (
                    <>
                        <IonFab vertical="bottom" horizontal="end" slot="fixed">
                            <IonFabButton
                                routerLink={`/copropietario/edificios/${idEdificio}/nuevo/reportes`}
                                className="fab-button-reportes"
                            >
                                <IonIcon icon={add} className="ion-add-icon" />
                            </IonFabButton>
                        </IonFab>
                        <IonGrid className="pb-50">
                            <IonRow className="dashboard-row">

                                {reportesByEdificio.length > 0 ? (
                                    reportesByEdificio.map((reporte: IReporte) => (
                                        <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6" key={reporte._id}>
                                            <ReporteListItem
                                                link={`/copropietario/edificios/${idEdificio}/reportes/${reporte._id}`}
                                                imgUrl={reporte.imgUrl || "#"}
                                                alt="reporte"
                                                reporte={reporte}
                                                onEdit={handleEdit}
                                                onDelete={handleDelete}
                                            />
                                        </IonCol>
                                    ))
                                ) : (
                                    <IonCol size="12" className="center-content">
                                        <p>No se encontraron reportes.</p>
                                    </IonCol>
                                )}
                            </IonRow>
                        </IonGrid>
                    </>

                )}
            </IonContent>
        </IonPage>
    );
};

export default ReportesCopList;
