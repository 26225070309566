import { action } from "typesafe-actions";
import { ReporteTypes, IReporte } from "./types";


export const postReporteRequest = (reporte: FormData, idEdificio: any) =>
  action(ReporteTypes.POST_REPORTE_REQUEST, { reporte, idEdificio });

export const postReporteSuccess = (reporte: IReporte, message: string, scope: string) =>
  action(ReporteTypes.POST_REPORTE_SUCCESS, { reporte, message, scope });

export const postReporteFail = (errorMessage: string, scope: string) =>
  action(ReporteTypes.POST_REPORTE_FAIL, { errorMessage, scope });

export const getReportesByEdificio = (idEdificio: string) =>
  action(ReporteTypes.GET_REPORTES_BY_EDIFICIO, { idEdificio });

export const setReportesByEdificio = (reportes: IReporte[], message: string, scope: string) =>
  action(ReporteTypes.SET_REPORTES_BY_EDIFICIO, { reportes, message, scope });

export const setReportesByEdificioFail = (message: string, scope: string) =>
  action(ReporteTypes.SET_REPORTES_BY_EDIFICIO_FAIL, { message, scope });
//el scope lo paso directo en el index
export const getReporteByIdRequest = (id: string) =>
  action(ReporteTypes.GET_REPORTE_BY_ID_REQUEST, { id });

export const getReporteByIdSuccess = (reporte: IReporte, message: string) =>
  action(ReporteTypes.GET_REPORTE_BY_ID_SUCCESS, { reporte, message });

export const getReporteByIdFail = (errorMessage: string) =>
  action(ReporteTypes.GET_REPORTE_BY_ID_FAIL, { errorMessage });
// Acción para iniciar la eliminación de un reporte
export const deleteReporteRequest = (id: string, scope: string) =>
  action(ReporteTypes.DELETE_REPORTE_REQUEST, { id, scope });

// Acción para manejar el éxito de la eliminación de un reporte
export const deleteReporteSuccess = (id: string, message: string, scope: string) =>
  action(ReporteTypes.DELETE_REPORTE_SUCCESS, { id, message, scope });

// Acción para manejar el fallo de la eliminación de un reporte
export const deleteReporteFail = (errorMessage: string, scope: string) =>
  action(ReporteTypes.DELETE_REPORTE_FAIL, { errorMessage, scope });

export const clearMessage = () => action(ReporteTypes.CLEAR_MESSAGE);

const actions = {
  postReporteRequest: postReporteRequest,
  postReporteSuccess: postReporteSuccess,
  postReporteFail: postReporteFail,
  getReportesByEdificio,
  setReportesByEdificio,
  setReportesByEdificioFail,
  clearMessage: clearMessage,
  deleteReporteRequest: deleteReporteRequest,
  deleteReporteSuccess: deleteReporteSuccess,
  deleteReporteFail: deleteReporteFail
};

export { actions as reporteActions };