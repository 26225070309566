import React from "react";
import {

    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonPage,
    IonRow,
    useIonViewWillEnter,
} from "@ionic/react";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/ducks/rootReducer";

import { RouteComponentProps } from "react-router";

import ToolbarBack from "../../components/Toolbars/ToolbarBack";
import { getVisitasByObra } from "../../store/ducks/visitas/actions";
import CustomSpinner from "../../components/Spinner/CustomSpinner";
import { IVisita } from "../../store/ducks/visitas/types";
import VisitaListItem from "../../components/Copropietario/Visitas/VisitaListItem";

type Params = { obraId: string | undefined, idEdificio: string };

const ObraCopSeguimiento: React.FC<RouteComponentProps<Params>> = ({
    match: {
        params: { obraId, idEdificio },
    },
}) => {
    const { visitasByObra, visitasLoad } = useSelector((state: RootState) => state.visitas);
    const dispatch = useDispatch();

    useIonViewWillEnter(() => {
        if (obraId && !visitasLoad) dispatch(getVisitasByObra(obraId));
    });


    return (
        <IonPage>
            <IonHeader className="ion-no-border border-header">
                <ToolbarBack title="Seguimiento" backLink={`/copropietario/edificios/${idEdificio}/obras/${obraId}`} />
            </IonHeader>

            {(!visitasLoad) ? (
                <IonContent fullscreen className="ion-content-grey">
                    <div className="center-content">
                        <CustomSpinner color="primary" />
                    </div>
                </IonContent>
            ) : (<IonContent fullscreen className="ion-content-grey">
                <IonGrid className="pb-50">
                    <IonRow className="dashboard-row">
                        {!visitasLoad ? (
                            <IonCol size="12" className="center-content">
                                <CustomSpinner color="primary" />
                            </IonCol>
                        ) : visitasByObra.length > 0 ? (
                            visitasByObra.map((visita: IVisita) => (
                                <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6" key={visita._id}>
                                    <VisitaListItem
                                        link={`/copropietario/edificios/${idEdificio}/obras/seguimiento/${obraId}/visitas/${visita._id}`}
                                        alt="visita"
                                        visita={visita}
                                    />
                                </IonCol>
                            ))
                        ) : (
                            <IonCol size="12" className="center-content">
                                <p>No se encontraron visitas.</p>
                            </IonCol>
                        )}

                    </IonRow>
                </IonGrid>
            </IonContent>)}
        </IonPage>
    );
};

export default ObraCopSeguimiento;