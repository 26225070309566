import { call, put, takeLatest } from "redux-saga/effects";
import {
  requestPostAvisos,
  requestGetAvisosByEdificio
} from "../../../api/avisos"; // Ajustar path según tu estructura de proyecto
import {
  postAvisoSuccess,
  postAvisoFail,
  setAvisosByEdificio,
  setAvisosByEdificioFail,
} from "./actions";
import {
  AvisoTypes,
  postAvisoRequestAction,
  IAviso,
  getAvisosByEdificioAction,
} from "./types";

/**
 * SAGA para POST Aviso
 */
function* postAvisoSaga(action: postAvisoRequestAction) {
  try {
    const aviso: FormData = action.payload.aviso;
    const idEdificio: any = action.payload.idEdificio;

    // Llamada a la API para crear un aviso
    // requestPostAviso podría ser algo como: requestPostAviso(idEdificio, aviso)
    const { data } = yield call(requestPostAvisos, idEdificio, aviso);

    // Suponiendo que el backend retorna { aviso: IAviso, message: string }
    const avisoResult: IAviso = data.aviso;
    const message: string = data.message;

    // Despachamos la acción de éxito
    yield put(postAvisoSuccess(avisoResult, message));
  } catch (error: any) {
    // Manejo de errores
    let errorMessage = "Ha ocurrido un error inesperado";

    if (error.response) {
      const { data } = error.response;
      if (data && data.message) {
        errorMessage = data.message;
      } else {
        errorMessage = "Error desconocido del servidor";
      }
      console.error(
        `Error del servidor: ${error.response.status} - ${error.response.statusText}`
      );
    } else if (error.request) {
      errorMessage =
        "No se pudo conectar al servidor. Por favor, verifica tu conexión a internet.";
      console.error("Error en la solicitud:", error.request);
    } else {
      errorMessage = `Error inesperado: ${error.message}`;
      console.error("Error inesperado:", error.message);
    }

    yield put(postAvisoFail(errorMessage));
  }
}

/**
 * SAGA para GET Avisos por Edificio
 */
function* getAvisosByEdificioSaga(action: getAvisosByEdificioAction) {
  try {
    const { idEdificio } = action.payload;

    // Llamada a la API para obtener avisos
    const { data } = yield call(requestGetAvisosByEdificio, idEdificio);

    // Suponiendo que el backend retorna { avisos: IAviso[], message: string }
    const avisosResult: IAviso[] = data.avisos;
    const message: string = data.message;

    // Despachamos la acción para guardar estos avisos en el store
    yield put(setAvisosByEdificio(avisosResult, message));
  } catch (error: any) {
    let errorMessage = "Ha ocurrido un error al obtener los avisos";

    if (error.response) {
      const { data } = error.response;
      if (data && data.message) {
        errorMessage = data.message;
      } else {
        errorMessage = "Error desconocido del servidor";
      }
      console.error(
        `Error del servidor: ${error.response.status} - ${error.response.statusText}`
      );
    } else if (error.request) {
      errorMessage =
        "No se pudo conectar al servidor. Verifica tu conexión a internet.";
      console.error("Error en la solicitud:", error.request);
    } else {
      errorMessage = `Error inesperado: ${error.message}`;
      console.error("Error inesperado:", error.message);
    }

    yield put(setAvisosByEdificioFail(errorMessage));
  }
}

/**
 * Watchers
 */
export function* avisosWatcherPostAviso() {
  yield takeLatest(AvisoTypes.POST_AVISO_REQUEST, postAvisoSaga);
}

export function* avisosWatcherGetAvisosByEdificio() {
  yield takeLatest(AvisoTypes.GET_AVISOS_BY_EDIFICIO, getAvisosByEdificioSaga);
}
