export const TIPOS_DESPERFECTO = [
    "Humedad",
    "Estructural",
    "Sanitario",
    "Pintura/Acabados",
    "Piso/Revestimientos",
    "Instalaciones Eléctricas",
    "Instalaciones Mecánicas",
    "Carpintería/Herrajes",
    "Techos y Cubiertas",
    "Mobiliario",
    "Otros",
]