import {
  call,
  put,
  takeLatest,
} from "redux-saga/effects";
import {
  requestPostReporte,
  requestGetReportesByEdificio,
  requestGetReporteById,
  requestDeleteReporteById
} from "../../../api/reporteDesperfectos";
import {
  postReporteSuccess,
  postReporteFail,
  setReportesByEdificio,
  setReportesByEdificioFail,
  getReporteByIdSuccess,
  getReporteByIdFail,
  deleteReporteSuccess,
  deleteReporteFail
} from "../reportes/actions";
import {
  ReporteTypes,
  PostReporteRequestAction,
  IReporte,
  GetReportesByEdificioAction,
  GetReporteByIdRequestAction,
  DeleteReporteRequestAction
} from "../reportes/types";
import { ReportesScopes } from "../../../utils/reportesScope";



function* postReporteSaga(action: PostReporteRequestAction) {
  try {
    const reporte: FormData = action.payload.reporte;
    const idEdificio: any = action.payload.idEdificio;
    const { data } = yield call(requestPostReporte, idEdificio, reporte);
    const reporteResult: IReporte = data.reporte;

    const message: string = data.message;
    yield put(postReporteSuccess(reporteResult, message, ReportesScopes.CREAR_REPORTE));
  } catch (error: any) {
    // Inicializar variable para almacenar mensaje de error
    let errorMessage = 'Ha ocurrido un error inesperado';

    // Verificar si es un error de respuesta del servidor
    if (error.response) {
      const { data } = error.response;

      // Verificar si el servidor proporcionó un mensaje de error específico
      if (data && data.message) {
        errorMessage = data.message;
      } else {
        errorMessage = 'Error desconocido del servidor';
      }

      // Opcional: Registrar el error en consola para depuración
      console.error(`Error del servidor: ${error.response.status} - ${error.response.statusText}`);
    } else if (error.request) {
      // Error relacionado con la solicitud (sin respuesta del servidor)
      errorMessage = 'No se pudo conectar al servidor. Por favor, verifica tu conexión a internet.';
      console.error('Error en la solicitud:', error.request);
    } else {
      // Otros errores no esperados
      errorMessage = `Error inesperado: ${error.message}`;
      console.error('Error inesperado:', error.message);
    }

    // Despachar acción de error con el mensaje procesado
    yield put(postReporteFail(errorMessage, ReportesScopes.CREAR_REPORTE));
  }
}

export function* deleteReporteSaga(action: DeleteReporteRequestAction) {
  try {
    const idReporte: string = action.payload.id; // ID del reporte a eliminar
    const scope: string = action.payload.scope; // Scope del componente

    // Llamada a la API para eliminar el reporte
    const { data } = yield call(requestDeleteReporteById, idReporte);

    // Despachar la acción de éxito
    yield put(
      deleteReporteSuccess(
        idReporte,
        data.message || "Reporte eliminado correctamente",
        scope
      )
    );
  } catch (error: any) {
    // Procesar el error
    let errorMessage = "Ha ocurrido un error inesperado";

    if (error.response) {
      const { data } = error.response;
      errorMessage = data?.message || "Error desconocido del servidor";
      console.error(
        `Error del servidor: ${error.response.status} - ${error.response.statusText}`
      );
    } else if (error.request) {
      errorMessage =
        "No se pudo conectar al servidor. Por favor, verifica tu conexión a internet.";
      console.error("Error en la solicitud:", error.request);
    } else {
      errorMessage = `Error inesperado: ${error.message}`;
      console.error("Error inesperado:", error.message);
    }

    // Despachar la acción de fallo con el mensaje procesado
    yield put(deleteReporteFail(errorMessage, action.payload.scope));
  }
}

function* getReportesByEdificioSaga(action: GetReportesByEdificioAction) {
  try {
    const { idEdificio } = action.payload;
    // Llamada a la API para obtener reportes
    const { data } = yield call(requestGetReportesByEdificio, idEdificio);
    // Suponiendo que el backend retorna algo como { reportes: IReporte[], message: string }
    const reportesResult: IReporte[] = data.reportes;
    console.log("reportesResult");
    console.log(data);
    const message: string = data.message;
    // Despachamos la acción para setear estos reportes en el store
    yield put(setReportesByEdificio(reportesResult, message, ReportesScopes.LISTA_REPORTES));
  } catch (error: any) {
    let errorMessage = "Ha ocurrido un error al obtener los reportes";

    if (error.response) {
      const { data } = error.response;
      if (data && data.message) {
        errorMessage = data.message;
      } else {
        errorMessage = "Error desconocido del servidor";
      }
      console.error(
        `Error del servidor: ${error.response.status} - ${error.response.statusText}`
      );
    } else if (error.request) {
      errorMessage =
        "No se pudo conectar al servidor. Verifica tu conexión a internet.";
      console.error("Error en la solicitud:", error.request);
    } else {
      errorMessage = `Error inesperado: ${error.message}`;
      console.error("Error inesperado:", error.message);
    }

    // Despachamos la acción en caso de fallo
    yield put(setReportesByEdificioFail(errorMessage, ReportesScopes.LISTA_REPORTES));
  }
}
function* getReporteByIdSaga(action: GetReporteByIdRequestAction) {
  
  try {
    console.log("Saga ejecutada: GET_REPORTE_BY_ID_REQUEST con payload:", action.payload);
    const { id } = action.payload;

    // Llamada a la API para obtener el reporte por ID
    const { data } = yield call(requestGetReporteById, id);
console.log(data);
    // Suponiendo que el backend retorna un objeto con el reporte
    const reporte: IReporte = data.reporte;
    const message: string = data.message;
    // Despachar la acción para setear el reporte en el store
    yield put(getReporteByIdSuccess(reporte, message));
  } catch (error: any) {
    let errorMessage = "Ha ocurrido un error al obtener el reporte";

    if (error.response) {
      const { data } = error.response;
      if (data && data.message) {
        errorMessage = data.message;
      } else {
        errorMessage = "Error desconocido del servidor";
      }
      console.error(
        `Error del servidor: ${error.response.status} - ${error.response.statusText}`
      );
    } else if (error.request) {
      errorMessage =
        "No se pudo conectar al servidor. Verifica tu conexión a internet.";
      console.error("Error en la solicitud:", error.request);
    } else {
      errorMessage = `Error inesperado: ${error.message}`;
      console.error("Error inesperado:", error.message);
    }

    // Despachar la acción en caso de fallo
    yield put(getReporteByIdFail(errorMessage));
  }
}


export function* reportesWatcherPostReporte() {
  yield takeLatest(ReporteTypes.POST_REPORTE_REQUEST, postReporteSaga);
}

export function* reportesWatcherGetReportesByEdificio() {
  yield takeLatest(ReporteTypes.GET_REPORTES_BY_EDIFICIO, getReportesByEdificioSaga);
}

export function* reportesWatcherGetReporteById() {
  yield takeLatest(ReporteTypes.GET_REPORTE_BY_ID_REQUEST, getReporteByIdSaga);
}

export function* reportesWatcherdeleteReporteById() {
  yield takeLatest(ReporteTypes.DELETE_REPORTE_REQUEST, deleteReporteSaga);
}