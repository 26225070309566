/**
 * types
 */
export enum InformeTypes {
  /*GET INFORMES BY TECNICO*/
  GET_INFORMES_BY_TECNICO = "GET_INFORMES_BY_TECNICO",
  /*SET INFORMES BY TECNICO*/
  SET_INFORMES_BY_TECNICO = "SET_INFORMES_BY_TECNICO",
  /*GET INFORMES BY EDIFICIO*/
  GET_INFORMES_BY_EDIFICIO = "GET_INFORMES_BY_EDIFICIO",
  /*SET INFORMES BY EDIFICIO*/
  SET_INFORMES_BY_EDIFICIO = "SET_INFORMES_BY_EDIFICIO",
  /*GET INFORME BY ID*/
  GET_INFORME_BY_ID = "GET_INFORME_BY_ID",
  /*SET INFORME BY ID*/
  SET_INFORME_BY_ID = "SET_INFORME_BY_ID",
  /*LOGOUT*/
  LOGOUT = "LOGOUT",
}

/**
 * Data types
 */
export interface IInforme {
  _id?: any;
  titulo?: string;
  descripcion?: string;
  tipo?: string;
  unidad?: string;
  unidadesVisitadas?: string[];
  sector?: string;
  edificio?: any;
  pdfUrl?: string;
  imgArchivoUrl?: string;
  nombreArchivo?: string;
  imgUrl?: string;
  tecnico?: { _id?: any; nombre?: string; apellido?: string };
  borrador?: boolean;
  informeAsociado?: any;
  readBy?: { readerId?: string; readedAt?: Date }[];
  createdBy?: any;
  updatedBy?: any;
  fechaVisita?: Date;
  createdAt?: Date;
  updatedAt?: Date;
  activo?: any;
}

/**
 * State type
 */
export interface InformeState {
  informesLoad: boolean;
  informeDetailLoad:boolean;
  inPromise: boolean;
  informesByTecnico: IInforme[] | [];
  informesByTecnicoBorrador: IInforme[] | [];
  informesByEdificio: IInforme[] | [];
  informesUnread: IInforme[] | [];
  informeById: IInforme;
  message: string;
  errorMessage: string;
}

/**
 * Action type
 */
export interface getInformesByTecnicoAction {
  type: typeof InformeTypes.GET_INFORMES_BY_TECNICO;
}
export interface setInformesAction {
  type: typeof InformeTypes.SET_INFORMES_BY_TECNICO;
  payload: { informes: IInforme[] };
}
export interface getInformesByEdificioAction {
  type: typeof InformeTypes.GET_INFORMES_BY_EDIFICIO;
  payload: { id: string };
}
export interface setInformesByEdificioAction {
  type: typeof InformeTypes.SET_INFORMES_BY_EDIFICIO;
  payload: { informes: IInforme[] };
}
export interface getInformeByIdAction {
  type: typeof InformeTypes.GET_INFORME_BY_ID;
  payload: { id: string };
}
export interface setInformeByIdAction {
  type: typeof InformeTypes.SET_INFORME_BY_ID;
  payload: { informe: IInforme };
}