import {
    IonHeader,
    IonToolbar,
    IonContent,
    IonSplitPane,
    IonMenu,
    IonRouterOutlet,
    IonMenuToggle,
    IonItem,
    IonIcon,
    useIonRouter,
    IonToast
} from "@ionic/react";
import {
    logOutOutline,
    personOutline,
} from "ionicons/icons";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/App.css";
import "./MenuCopropietario.css";
import { useEffect, useState, lazy, Suspense } from "react";
import { checkAuthStatus, logout } from "../../store/ducks/users/actions";
import TabsCopropietario from "../TabsCopropietario/TabsCopropietario";
import Perfil from "../Perfil/Perfil";
import MemoriasCopList from "../MemoriasCopropietario/MemoriasCopList";
import ConsultasCopList from "../ConsultasCopropietario/ConsultasCopList";
import AvisosCopList from "../AvisosCopropietario/AvisosCopList";
import { Route, useLocation, useParams } from "react-router-dom";
import PrivateRoute from "../../routes/PrivateRoute";

import { RootState } from "../../store/ducks/rootReducer";
import { getUserId } from "../../utils/auth";
import CenteredLoader from "../../components/CenteredLoader/CenteredLoader";
import ReportesCopCreate from "../ReportesCopropietarios/ReportesCopCreate";

// Lazy loading de los componentes
//const Perfil = lazy(() => import("../Perfil/Perfil"));
//const MemoriasCopList = lazy(() => import("../MemoriasCopropietario/MemoriasCopList"));
//const ConsultasCopList = lazy(() => import("../ConsultasCopropietario/ConsultasCopList"));
//const AvisosCopList = lazy(() => import("../AvisosCopropietario/AvisosCopList"));
//const TabsCopropietario = lazy(() => import("../TabsCopropietario/TabsCopropietario"));

const MenuCopropietario: React.FC = () => {
    const { idEdificio } = useParams<{ idEdificio: string }>();
    const navigation = useIonRouter();


    const dispatch = useDispatch();



    const path = [
        { name: "Mi perfil", url: "/perfil", icon: personOutline },
    ];


    function salir() {
        if (getUserId() !== "") localStorage.removeItem("userId");
        dispatch(logout());
        navigation.push('/', 'root', 'replace');
    }



    return (

        <IonSplitPane contentId="main" when="false">

            <IonMenu contentId="main">
                <IonHeader className="ion-no-border border-header">
                    <IonToolbar className="menu-toolbar d-flex">
                        <div className="ion-logo-splitPane"></div>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    {path.map((item, index) => (
                        <IonMenuToggle key={index} auto-hide="false">
                            <IonItem routerLink={item.url} routerDirection="none" className="item-menu">
                                <IonIcon icon={item.icon} slot="start" className="icon-menu"></IonIcon>
                                {item.name}
                            </IonItem>
                        </IonMenuToggle>
                    ))}
                    <IonItem button onClick={salir} className="item-menu">
                        <IonIcon icon={logOutOutline} slot="start" className="icon-menu" />
                        Salir
                    </IonItem>

                </IonContent>
            </IonMenu>


            <IonRouterOutlet id="main">


                <PrivateRoute path="/perfil" component={Perfil} />
                <PrivateRoute exact path="/memorias" component={MemoriasCopList} />
                <PrivateRoute exact path="/consultas" component={ConsultasCopList} />
                <PrivateRoute exact path="/avisos" component={AvisosCopList} />
                <PrivateRoute path="/copropietario" component={TabsCopropietario} />
              
            </IonRouterOutlet>


        </IonSplitPane>


    );
};

export default MenuCopropietario;
