import { IonReactRouter } from "@ionic/react-router";
import { IonApp, setupIonicReact } from "@ionic/react";
/* PrivateRoutes */
import PrivateRoutes from "./routes/PrivateRoute";
/* Utils */
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
/* Theme variables */
import "./theme/variables.css";
import "./theme/global.css";
import "./styles/App.css";
import { App as CapacitorApp } from '@capacitor/app';
import Main from './pages/Main';
import { useEffect } from "react";
//const Main = React.lazy(() => import('./pages/Main'));


setupIonicReact();

const App: React.FC = () => {
  
  return (
    <IonApp>
      <IonReactRouter>
          <Main />
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
