export const SECTORES = [
    "Fachada",
    "Medianera",
    "Pasillo",
    "Escalera",
    "Circulación",
    "Gimnasio",
    "Azotea",
    "Barbacoa",
    "Instalaciones",
    "Terraza",
    "Unidad",
    "Pozo de aire",
    "Depósito",
    "Sala de máquinas",
    "Estacionamiento",
    "Hall",
    "Patio",
    "Ascensor",
    "Portería",
    "Bicicletero",
    "Lavadero",
    "SUM",
    "Piscina",
    "Jardín",
    "Sauna",
    "Cuarto de basura",
    "Tanque de agua",
    "Cámaras de vigilancia",
    "Otro",
];
