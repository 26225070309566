import React, { useEffect, useState } from "react";

import {
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonPage,
    IonRow,
    IonText,
    useIonViewWillEnter,
    useIonViewWillLeave,
} from "@ionic/react";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/ducks/rootReducer";

import { RouteComponentProps } from "react-router";

import { list, briefcase, images, reader, documentText, cash, analytics } from "ionicons/icons";

import ToolbarBack from "../../components/Toolbars/ToolbarBack";

import { clearMessage, getReporteByIdRequest } from "../../store/ducks/reportes/actions";
import ObraItem from "../../components/Copropietario/Obras/ObraItem";
import ObraDescripcionCard from "../../components/Copropietario/Obras/ObraDescripcionCard";
import CustomSpinner from "../../components/Spinner/CustomSpinner";
import ReporteDetailCard from "../../components/Copropietario/ReportesDesperfectos/ReporteDetailCard";
import { ReportesScopes } from "../../utils/reportesScope";

type Params = { reporteId: string, idEdificio: string };

const ReportesCopDetail: React.FC<RouteComponentProps<Params>> = ({
    match: {
        params: { reporteId, idEdificio },
    },
}) => {

    const { reporteById, scope, errorMessage, reporteByIdLoading, reporteByIdFail } = useSelector(
        (state: RootState) => state.reportes
    );
    const dispatch = useDispatch();

    useEffect(() => {

        if (!reporteById || reporteById._id !== reporteId) {
            dispatch(getReporteByIdRequest(reporteId));
        } 

    }, [reporteById]);


   

    const displayErrorMessage =
        errorMessage && scope === ReportesScopes.DETALLE_REPORTE
            ? errorMessage
            : "No se encontró el reporte o ocurrió un error al cargarlo.";


    return (

        <IonPage>
            <IonHeader className="ion-no-border border-header">
                <ToolbarBack title="Reporte Desperfecto" backLink={`/copropietario/edificios/${idEdificio}/reportes`} />
            </IonHeader>
            <IonContent fullscreen className="ion-content-grey">
                {reporteByIdLoading && (
                    <>
                        
                        <div className="center-content">
                            <CustomSpinner color="primary" />
                        </div>
                    </>
                )}
                {reporteById && (
                    <>
                        <IonGrid className="pb-50">
                            <IonRow className="dashboard-row">
                                <IonCol size-md="12" size-lg="12" size-xs="12" size-sm="12">
                                    <ReporteDetailCard reporte={reporteById} />
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </>
                )}
                {reporteByIdFail && (
                    <>
                        <IonGrid className="pb-50">
                            <IonRow className="dashboard-row">
                                <IonCol size="12">
                                    <IonText color="danger">{displayErrorMessage}</IonText>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </>
                )}
            </IonContent>
        </IonPage>
    );
};

export default ReportesCopDetail;