import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
} from "@ionic/react";

import "./EscritorioItem.css";

import { businessOutline } from 'ionicons/icons';
import React, { ReactElement } from "react";
import moment from "moment";
import "moment/locale/es";
import { FiCalendar } from "react-icons/fi";

export type CardProps = {
  title: string;
  fechaPublicacion?: Date;
  iconClassName?: string;
  link: string;
  iconName: string;
  cantidad?: string;
  displayCantidad: boolean;
  descripcion?: string | undefined
};

const EscritorioItem: React.FC<CardProps> = ({
  title,
  link,
  iconName,
  cantidad,
  fechaPublicacion,
  displayCantidad,
  descripcion
}) => {
  return (
    <IonCard className="ion-card-menu escritorio-item-card-content" routerLink={link} routerDirection="forward">
      <IonCardHeader>
        <IonIcon icon={iconName} className="ion-icon-menu-detalles"></IonIcon>
      </IonCardHeader>
      <IonCardContent className="ion-card-escritorio-content">
        <h1 className="mb-3 text-capitalize font-bold font-dark-grey">
          {title}
        </h1>
       
        {displayCantidad &&
          (<div className="d-flex align-items-center justify-content-between mb-3 h-100">
            <h4>
              Registros
            </h4>
            {cantidad !== undefined ?
              <h2 className="font-bold font-dark-grey">{cantidad}</h2> :
              <h2 className="font-bold font-dark-grey">0</h2>}

          </div>)
        }
        <div className="d-flex ion-align-items-center text-small">
          {fechaPublicacion !== undefined ?
            <span>
              {`Actualizado el ${moment(fechaPublicacion).locale("es").format("LL")}`}
            </span> : <span>
              {`Sin actualizaciones`}
            </span>}

        </div>
      </IonCardContent>
    </IonCard>
  );
}

export default EscritorioItem;
