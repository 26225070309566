import { Reducer } from "redux";
import { AvisoTypes, AvisoState, AvisoActionTypes } from "./types";

const initialState: AvisoState = {
  // Estados iniciales
  inPromise: false,
  avisoByIdLoad: false,
  avisosLoad: false,
  avisoById: null,
  avisoNuevo: null,
  avisosByEdificio: [],
  avisosUnread: [],
  message: "",
  errorMessage: ""
};

export const avisoReducer: Reducer<AvisoState> = (
  state = initialState,
  action: AvisoActionTypes
) => {
  switch (action.type) {
    /**
     * POST AVISO
     */
    case AvisoTypes.POST_AVISO_REQUEST:
      return {
        ...state,
        inPromise: true,
        errorMessage: "",
        message: ""
      };

    case AvisoTypes.POST_AVISO_SUCCESS:
      return {
        ...state,
        inPromise: false,
        avisoNuevo: action.payload.aviso,
        message: action.payload.message,
        errorMessage: ""
      };

    case AvisoTypes.POST_AVISO_FAIL:
      return {
        ...state,
        inPromise: false,
        message: "",
        errorMessage: action.payload.message
      };

    /**
     * GET AVISOS BY EDIFICIO
     */
    case AvisoTypes.GET_AVISOS_BY_EDIFICIO:
      return {
        ...state,
        inPromise: true,
        avisosLoad: false,
        message: "",
        errorMessage: ""
      };

    /**
     * SET AVISOS BY EDIFICIO
     */
    case AvisoTypes.SET_AVISOS_BY_EDIFICIO:
      return {
        ...state,
        inPromise: false,
        avisosByEdificio: action.payload.avisos,
        avisosLoad: true,
        message: action.payload.message,
        errorMessage: ""
      };

    /**
     * SET AVISOS BY EDIFICIO FAIL
     */
    case AvisoTypes.SET_AVISOS_BY_EDIFICIO_FAIL:
      return {
        ...state,
        inPromise: false,
        message: "",
        errorMessage: action.payload.message
      };

    /**
     * CLEAR MESSAGE
     */
    case AvisoTypes.CLEAR_MESSAGE:
      return {
        ...state,
        message: "",
        errorMessage: ""
      };

    /**
     * LOGOUT
     */
    case AvisoTypes.LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
};
