import React, { useEffect, useState } from "react";
import {
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonPage,
    IonRow
} from "@ionic/react";
import { App as CapacitorApp } from '@capacitor/app';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/ducks/rootReducer";

import { RouteComponentProps, useHistory, useLocation } from "react-router";

import ToolbarBack from "../../components/Toolbars/ToolbarBack";
import CustomSpinner from "../../components/Spinner/CustomSpinner";
import { getObservacionesByVisita } from "../../store/ducks/observaciones/actions";
import { IObservacion } from "../../store/ducks/observaciones/types";
import ObservacionDetallesCard from "../../components/Copropietario/Observaciones/ObservacionesDetallesCard";
import ModalImagen from "../../components/ModalImagen/ModalImagen";

type Params = { obraId: string | undefined, visitaId: string | undefined, idEdificio: string | undefined };

const ObraCopVisitaObservaciones: React.FC<RouteComponentProps<Params>> = ({
    match: {
        params: { obraId, visitaId, idEdificio }
    },
}) => {

    const { observacionesByVisita, observacionesLoad } = useSelector((state: RootState) => state.observaciones);
   
    const location = useLocation();
  
  
    

    const dispatch = useDispatch();

    useEffect(() => {
        if (visitaId && !observacionesLoad) dispatch(getObservacionesByVisita(visitaId));
    }, [visitaId, observacionesLoad]);
  

    return (
        <IonPage>
            <IonHeader className="ion-no-border border-header">
                <ToolbarBack
                    title="Observaciones"
                    backLink={`/copropietario/edificios/${idEdificio}/obras/seguimiento/${obraId}/visitas/${visitaId}`}
                />
            </IonHeader>

            {(!observacionesLoad) ? (
                <IonContent fullscreen className="ion-content-grey">
                    <div className="center-content">
                        <CustomSpinner color="primary" />
                    </div>
                </IonContent>
            ) : (<IonContent fullscreen className="ion-content-grey">
                <IonGrid className="pb-50">
                    <IonRow className="dashboard-row">
                        {!observacionesLoad ? (
                            <IonCol size-md="12" size-lg="12" size-xs="12" size-sm="12">
                                <CustomSpinner color="primary" />
                            </IonCol>
                        ) : (
                            observacionesByVisita.map((observacion: IObservacion, index: number) => (
                                <IonCol
                                    key={observacion._id || index}
                                    size-md="12"
                                    size-lg="12"
                                    size-xs="12"
                                    size-sm="12"
                                >
                                    <ObservacionDetallesCard
                                        observacion={observacion}
                                        titulo={`Observación ${index + 1}`}                                        
                                    />
                                </IonCol>
                            ))
                        )}
                    </IonRow>
                </IonGrid>
            </IonContent>)}
            {/* Renderizar el modal si está abierto */}
            {location.search.includes("modalOpened=true") && <ModalImagen />}
      
     
        </IonPage>
    );
};

export default ObraCopVisitaObservaciones;