import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
    useIonViewDidEnter,
    useIonViewWillEnter,
} from "@ionic/react";
import "../../styles/App.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/ducks/rootReducer";
import React, { useEffect } from "react";

import { RouteComponentProps } from "react-router";

import ToolbarBack from "../../components/Toolbars/ToolbarBack";
import { getInformeById } from "../../store/ducks/informes/actions";

type Params = { informeId: string | undefined, idEdificio: string | undefined };

const InformeCopNotas: React.FC<RouteComponentProps<Params>> = ({
    match: {
        params: { informeId, idEdificio },
    },
}) => {
    const { _id, userProfile } = useSelector((state: RootState) => state.users);
    const { informeDetailLoad, informeById } = useSelector((state: RootState) => state.informes);

    const dispatch = useDispatch();
    useEffect(() => {

    }, []);
    useIonViewWillEnter(() => {
        if (informeId && !informeDetailLoad) dispatch(getInformeById(informeId));
    });

   
    return (
        <IonPage>
            <IonHeader className="ion-no-border border-header">
                <ToolbarBack title="Informe notas" backLink={`/copropietario/edificios/${idEdificio}/informes/${informeId}`}/>
            </IonHeader>

            <IonContent fullscreen className="ion-content-grey">
                <IonGrid className="pb-50">
                {(informeById && informeDetailLoad) && (
                        <IonRow className="dashboard-row">
                            <IonCol size-md="6" size-lg="6" size-xs="12" size-sm="6">
                                
                            </IonCol>
                            
                        </IonRow>
                    )}
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default InformeCopNotas;