import { IEdificio } from "../edificios/types"

/**
 * types
 */
export enum ObraTypes {
    /*GET OBRAS*/
    GET_OBRAS_BY_EDIFICIO = "GET_OBRAS_BY_EDIFICIO",
    SET_OBRAS_BY_EDIFICIO = "SET_OBRAS_BY_EDIFICIO",
    GET_OBRA_BY_ID = "GET_OBRA_BY_ID",
    SET_OBRA_BY_ID = "SET_OBRA_BY_ID",
    /*LOGOUT*/
    LOGOUT = "LOGOUT"
}

/**
 * Data types
 */
export interface IObra {
    _id?: any;
    expediente?: string;
    titulo?: string;
    descripcion?: string;
    sectores?: any[];
    rubros?: any[];
    unidades?: string[];
    estado?: string;
    avance?: number;
    fechaInicio?: Date;
    fechaFin?: Date;
    imgUrl?: string;
    edificio?: IEdificio;
    empresa?: any;
    presupuesto?: any;
    informe?: any;
    recaudosGraficos?: any[];
    visitas?: any[];
    notas?: any[];
    createdBy?: any;
    updatedBy?: any;
    activo?: boolean;
    createdAt?: Date;
    updatedAt?: Date;
    readBy?: { readerId?: string, readedAt?: Date }[];

    // Nuevos campos agregados
    ultimaNotaFecha?: Date | null;
    ultimaVisitaFecha?: Date | null;
    ultimaVisitaActualizacion?: Date | null;
    cantidadRecaudosGraficos?: number;
}

/**
 * State type
 */
export interface ObraState {
    obrasLoad: boolean;
    obraByIdLoad: boolean;
    obraById: IObra | {};
    inPromise: boolean;
    obras: IObra[] | [];
    obrasUnread: IObra[] | [];
    message: string;
    errorMessage: string;
}

/**
 * Action type
 */
export interface GetObrasAction {
    type: typeof ObraTypes.GET_OBRAS_BY_EDIFICIO;
    payload: { id: string }
}
export interface SetObrasAction {
    type: typeof ObraTypes.SET_OBRAS_BY_EDIFICIO;
    payload: { obras: IObra[] }
}
export interface GetObraByIdAction {
    type: typeof ObraTypes.GET_OBRA_BY_ID;
    payload: { id: string }
}
export interface SetObraByIdAction {
    type: typeof ObraTypes.SET_OBRA_BY_ID;
    payload: { obra: IObra }
}
export interface LogoutAction {
    type: typeof ObraTypes.LOGOUT;
  }
export type ObraActionTypes =
  | GetObrasAction
  | SetObrasAction
  | GetObraByIdAction
  | SetObraByIdAction
  | LogoutAction