import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store/ducks/rootReducer";
import CenteredLoader from "../components/CenteredLoader/CenteredLoader";

const LoginRoute = ({ component: Component, userHomePath, ...rest }: any) => {
  const { isLogged, checkingAuth, userProfile } = useSelector(
    (state: RootState) => state.users
  );

  const isReadyToRedirect = isLogged && userProfile.tipo!=="" && userHomePath!=="";

  return (
    <Route
      {...rest}
      render={(props) =>
        !isLogged && checkingAuth ? (
          <CenteredLoader
            isOpen={checkingAuth}
            message="Verificando autenticación..."
          />
        ) : !isLogged && !checkingAuth ? (
          <Component {...props}/>
        ) : (
          <Redirect to={userHomePath}/>
        ) 
      }
    />
  );
};

export default LoginRoute;
