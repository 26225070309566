import { Route, Redirect } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/ducks/rootReducer";
import CenteredLoader from "../components/CenteredLoader/CenteredLoader"; // Loader mientras se verifica la autenticación
import { checkAuthStatus } from "../store/ducks/users/actions"; // Acción que dispara el saga de verificación

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const dispatch = useDispatch();
  
  // Obtiene el estado actual de autenticación desde Redux
  const { isLogged, checkingAuth, userProfile } = useSelector((state: RootState) => state.users);

  // Efecto para verificar la autenticación cuando se monta el componente


  return (
    <Route
      {...rest}
      render={(props) =>
        (!isLogged && checkingAuth)? (
          // Mientras se verifica la autenticación, muestra un loader
          <CenteredLoader isOpen={checkingAuth} message="Cargando..."/>
        ) : isLogged ? (
          // Si el usuario está autenticado, renderiza el componente protegido
          <Component {...props} />
        ) : (
          // Si el usuario no está autenticado, redirige a la página de login
          <Redirect to="/" />
        )
      }
    />
  );
};

export default PrivateRoute;


