import { combineReducers } from "redux";
import { userReducer } from "./users";
import { edificioReducer } from "./edificios";
import { obraReducer } from "./obras"
import { informeReducer } from "./informes";
import { visitaReducer } from "./visitas";
import { observacionReducer} from "./observaciones";
import { reporteReducer} from "./reportes";
import { avisoReducer } from "./avisos";

const rootReducer = combineReducers({
  users: userReducer,
  edificios: edificioReducer,
  obras: obraReducer,
  informes: informeReducer,
  visitas: visitaReducer,
  observaciones: observacionReducer,
  reportes: reporteReducer,
  avisos: avisoReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;