import React, { useEffect } from "react";
import {
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonPage,
    IonRow,
    useIonViewWillEnter,
} from "@ionic/react";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/ducks/rootReducer";

import { RouteComponentProps } from "react-router";

import ToolbarBack from "../../components/Toolbars/ToolbarBack";
import { getVisitaById, getVisitasByObra } from "../../store/ducks/visitas/actions";
import CustomSpinner from "../../components/Spinner/CustomSpinner";
import VisitaDescripcionCard from "../../components/Copropietario/Visitas/VisitaDescriptionCard";
import { getObservacionesByVisita } from "../../store/ducks/observaciones/actions";
import VisitaDetallesItem from "../../components/Copropietario/Visitas/VisitaDetallesItem";
import { alertCircle } from "ionicons/icons";

type Params = { obraId: string | undefined, visitaId: string | undefined, idEdificio: string | undefined };

const ObraCopVisita: React.FC<RouteComponentProps<Params>> = ({
    match: {
        params: { obraId, visitaId, idEdificio }
    },
}) => {
    const { visitaById, visitaByIdLoad } = useSelector((state: RootState) => state.visitas);

    const { observacionesByVisita, observacionesLoad } = useSelector((state: RootState) => state.observaciones);
    const toolbarTitle = visitaById?.titulo || "Visita";
    const dispatch = useDispatch();

    useEffect(() => {
        if (visitaByIdLoad && visitaId && !observacionesLoad) dispatch(getObservacionesByVisita(visitaId));
    }, [visitaById, observacionesByVisita]);

    useIonViewWillEnter(() => {
        if (visitaId && !visitaByIdLoad) dispatch(getVisitaById(visitaId));

    });


    return (
        <IonPage>
            <IonHeader className="ion-no-border border-header">
                <ToolbarBack title={toolbarTitle} backLink={`/copropietario/edificios/${idEdificio}/obras/seguimiento/${obraId}`} />
            </IonHeader>

            {(!visitaByIdLoad) ? (
                <IonContent fullscreen className="ion-content-grey">
                    <div className="center-content">
                        <CustomSpinner color="primary" />
                    </div>
                </IonContent>
            ) : (<IonContent fullscreen className="ion-content-grey">


                <IonGrid className="pb-50">
                    <IonRow className="dashboard-row">

                        <IonCol size-md="12" size-lg="12" size-xs="12" size-sm="12">

                            <VisitaDescripcionCard visita={visitaById} />

                        </IonCol>
                    </IonRow>

                    <IonRow className="dashboard-row">
                        <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                            <VisitaDetallesItem
                                link={`/copropietario/edificios/${idEdificio}/obras/seguimiento/${obraId}/visitas/${visitaId}/observaciones`}
                                title="Observaciones"
                                subTitle={`Correcciones solicitadas (${observacionesByVisita.length})`}
                                iconName={alertCircle}
                            />
                        </IonCol>

                    </IonRow>

                </IonGrid>


            </IonContent>)}


        </IonPage>
    );
};

export default ObraCopVisita;