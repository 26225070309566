import React from "react";
import { IonPopover, IonList, IonItem, IonLabel, IonIcon } from "@ionic/react";
import "./OptionsPopover.css"
import { createOutline, trashBinOutline, trashOutline } from "ionicons/icons";

type OptionsPopoverProps = {
    isOpen: boolean;

    event: MouseEvent | undefined;
    onDismiss: () => void;
    onEdit: () => void;
    onDelete: () => void;
};

const OptionsPopover: React.FC<OptionsPopoverProps> = ({
    isOpen,
    event,
    onDismiss,
    onEdit,
    onDelete,
}) => {
    return (
        <IonPopover isOpen={isOpen} event={event} onDidDismiss={onDismiss}>
            <IonList>
                <IonItem button className="item-button" onClick={(e) => {
                    e.stopPropagation(); 
                    onEdit(); 
                }}>
                    <IonIcon className="icon-item-option" icon={createOutline}></IonIcon>
                    <IonLabel>Editar</IonLabel>
                </IonItem>
                <IonItem className="item-border-none item-button delete" button onClick={(e) => {
                    e.stopPropagation(); 
                    onDelete();
                }}>
                    <IonIcon className="icon-item-option" icon={trashOutline}></IonIcon>
                    <IonLabel className="label-delete">Eliminar</IonLabel>
                </IonItem>
            </IonList>
        </IonPopover>
    );
};

export default OptionsPopover;
