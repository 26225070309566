import { IEdificio } from "../edificios/types";
import { IUserCopropietario } from "../users/types";

/**
 * Types
 */
export enum AvisoTypes {
  /*POST AVISO*/
  POST_AVISO_REQUEST = "POST_AVISO_REQUEST",
  POST_AVISO_SUCCESS = "POST_AVISO_SUCCESS",
  POST_AVISO_FAIL = "POST_AVISO_FAIL",
  /*GET AVISOS BY EDIFICIO*/
  GET_AVISOS_BY_EDIFICIO = "GET_AVISOS_BY_EDIFICIO",
  SET_AVISOS_BY_EDIFICIO = "SET_AVISOS_BY_EDIFICIO",
  SET_AVISOS_BY_EDIFICIO_FAIL = "SET_AVISOS_BY_EDIFICIO_FAIL",
  /*CLEAR MESSAGE*/
  CLEAR_MESSAGE = "CLEAR_MESSAGE",
  /*LOGOUT*/
  LOGOUT = "LOGOUT",
}

/**
 * Data types
 */
export interface IAviso {
  _id?: any;
  edificio?: IEdificio | string;
  titulo?: string;
  contenido?: string;
  prioridad?: "baja" | "media" | "alta";
  estado?: "activo" | "resuelto" | "archivado";
  categoria?:
    | "mantenimiento"
    | "evento"
    | "normas"
    | "pagos"
    | "seguridad"
    | "areas_comunes"
    | "denuncias"
    | "perdidos_y_encontrados"
    | "otros";
  tipoUsuarios?: string[]; // Roles permitidos en el aviso
  etiquetas?: string[];
  imgUrl?: string;
  createdBy?: {_id: any, username: string};
  updatedBy?: {_id: any, username: string};
 
  readBy?: { readerId?: string, readedAt?: Date }[];
  createdAt?: Date;
  updatedAt?: Date;
}

/**
 * State type
 */
export interface AvisoState {
  avisosLoad: boolean;
  avisoByIdLoad: boolean;
  avisoById: IAviso | null;
  avisoNuevo: IAviso | null;
  inPromise: boolean;
  avisosByEdificio: IAviso[] | [];
  avisosUnread: IAviso[] | [];
  message: string;
  errorMessage: string;
}

/**
 * Action types
 */
export interface postAvisoRequestAction {
  type: typeof AvisoTypes.POST_AVISO_REQUEST;
  payload: { aviso: FormData; idEdificio: any };
}

export interface postAvisoSuccessAction {
  type: typeof AvisoTypes.POST_AVISO_SUCCESS;
  payload: { aviso: IAviso; message: string };
}

export interface postAvisoFailAction {
  type: typeof AvisoTypes.POST_AVISO_FAIL;
  payload: { message: string };
}

export interface getAvisosByEdificioAction {
  type: typeof AvisoTypes.GET_AVISOS_BY_EDIFICIO;
  payload: { idEdificio: string };
}

export interface setAvisosByEdificioAction {
  type: typeof AvisoTypes.SET_AVISOS_BY_EDIFICIO;
  payload: {
    avisos: IAviso[];
    message: string;
  };
}

export interface setAvisosByEdificioFailAction {
  type: typeof AvisoTypes.SET_AVISOS_BY_EDIFICIO_FAIL;
  payload: { message: string };
}

export interface clearMessageAction {
  type: typeof AvisoTypes.CLEAR_MESSAGE;
}

export interface LogoutAction {
  type: typeof AvisoTypes.LOGOUT;
}

/**
 * Union de acciones
 */
export type AvisoActionTypes =
  | postAvisoRequestAction
  | postAvisoSuccessAction
  | postAvisoFailAction
  | getAvisosByEdificioAction
  | setAvisosByEdificioAction
  | setAvisosByEdificioFailAction
  | clearMessageAction
  | LogoutAction;
