import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  isPlatform,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from "@ionic/react";
import "../../styles/App.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/ducks/rootReducer";
import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router";

import ToolbarBack from "../../components/Toolbars/ToolbarBack";
import ArchivoCard from "../../components/ArchivoCard/ArchivoCard";
import { getInformeById } from "../../store/ducks/informes/actions";
import CustomSpinner from "../../components/Spinner/CustomSpinner";

type Params = { informeId: string | undefined, idEdificio: string | undefined };
const InformeCopArchivos: React.FC<RouteComponentProps<Params>> = ({
  match: {
    params: { informeId, idEdificio },
  },
}) => {
  const { _id, userProfile } = useSelector((state: RootState) => state.users);
  const { informeDetailLoad, informeById } = useSelector((state: RootState) => state.informes);

  const dispatch = useDispatch();

  useIonViewWillEnter(() => {
    if (informeId && !informeDetailLoad) dispatch(getInformeById(informeId));
  });


  return (
    <IonPage>
      <IonHeader className="ion-no-border border-header">
        <ToolbarBack title="Informe archivos" backLink={`/copropietario/edificios/${idEdificio}/informes/${informeId}`} />
      </IonHeader>
      {(!informeDetailLoad) ? (
        <IonContent fullscreen className="ion-content-grey">
          <div className="center-content">
            <CustomSpinner color="primary" />
          </div>
        </IonContent>
      ) : (
        <IonContent fullscreen className="ion-content-grey">
          <IonGrid className="pb-50">

            <IonRow className="dashboard-row">

              <IonCol size-md="4" size-lg="3" size-xs="12" size-sm="6">
                <ArchivoCard
                  nombre={informeById.nombreArchivo}
                  alt={informeById.titulo}
                  src={informeById.imgArchivoUrl}
                  pdfUrl={informeById.pdfUrl}
                />
              </IonCol>
            </IonRow>

          </IonGrid>
        </IonContent>)}
    </IonPage>
  );
};

export default InformeCopArchivos;
