import { action } from "typesafe-actions";
import { AvisoTypes, IAviso } from "./types";

/**
 * POST AVISO
 */
export const postAvisoRequest = (aviso: FormData, idEdificio: any) =>
  action(AvisoTypes.POST_AVISO_REQUEST, { aviso, idEdificio });

export const postAvisoSuccess = (aviso: IAviso, message: string) =>
  action(AvisoTypes.POST_AVISO_SUCCESS, { aviso, message });

export const postAvisoFail = (message: string) =>
  action(AvisoTypes.POST_AVISO_FAIL, { message });

/**
 * GET AVISOS BY EDIFICIO
 */
export const getAvisosByEdificio = (idEdificio: string) =>
  action(AvisoTypes.GET_AVISOS_BY_EDIFICIO, { idEdificio });

export const setAvisosByEdificio = (avisos: IAviso[], message: string) =>
  action(AvisoTypes.SET_AVISOS_BY_EDIFICIO, { avisos, message });

export const setAvisosByEdificioFail = (message: string) =>
  action(AvisoTypes.SET_AVISOS_BY_EDIFICIO_FAIL, { message });

/**
 * CLEAR MESSAGE
 */
export const clearMessage = () =>
  action(AvisoTypes.CLEAR_MESSAGE);

/**
 * Agrupación de acciones para exportación
 */
const actions = {
  postAvisoRequest,
  postAvisoSuccess,
  postAvisoFail,
  getAvisosByEdificio,
  setAvisosByEdificio,
  setAvisosByEdificioFail,
  clearMessage
};

export { actions as avisoActions };
