import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
} from "@ionic/react";
import "./InformeItem.css";

import { businessOutline, chevronForwardOutline, arrowForwardOutline } from 'ionicons/icons';
import React, { ReactElement } from "react";
import moment from "moment";
import "moment/locale/es";
import { FiCalendar } from "react-icons/fi";

export type InformeItemsProps = {
  title: string;
  link: string;
  iconName: string;
  subTitle? : string
};

const InformeItem: React.FC<InformeItemsProps> = ({
  link,
  title,
  iconName,
  subTitle
}) => {
  return (
    <IonCard className="ion-card-menu" routerLink={link} routerDirection="forward">

      <IonCardContent className="ion-card-item-content">
        <div className="d-flex align-items-center informe-item-box">
          <IonIcon icon={iconName} className="ion-icon-informe-item float-left"></IonIcon>
          <div className="obra-item-text">
            <h4 className="font-weight-500 text-capitalize font-dark-grey float-left ml-2">
              {title}
            </h4>
            <p className="ml-2">
              {subTitle}
            </p>
          </div>
          <IonIcon icon={chevronForwardOutline} className="ion-icon-informe-forward float-right"></IonIcon>
        </div>

      </IonCardContent>
    </IonCard>
  );
}

export default InformeItem;