import {
  IonContent,
  IonHeader,
  IonPage,
  useIonViewWillEnter,
} from "@ionic/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/ducks/rootReducer";
import "../Dashboard/Dashboard.css";
import "../../styles/App.css";
import ToolbarMenu from "../../components/Toolbars/ToolbarMenu";
import { getAvisosByEdificio } from "../../store/ducks/avisos/actions";
import { RouteComponentProps } from "react-router";

type Params = { idEdificio: string };

const AvisosCopList: React.FC<RouteComponentProps<Params>> = ({
  match: {
    params: { idEdificio },
  },
}) => {
  const { avisosByEdificio, inPromise } = useSelector((state: RootState) => state.avisos);
  const dispatch = useDispatch();
  
  useIonViewWillEnter(() => {
    dispatch(getAvisosByEdificio(idEdificio));
  });

  useEffect(() => {
    console.log("avisosByEdificio cambió:", avisosByEdificio);
  }, [avisosByEdificio]);

  return (
    <IonPage className="bg-color-grey">
      <IonHeader className="ion-no-border border-header">
        <ToolbarMenu title="Avisos" />
      </IonHeader>

      <IonContent fullscreen className="ion-content-grey"></IonContent>
    </IonPage>
  );
};

export default AvisosCopList;