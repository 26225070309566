import {
    IonTabs,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonLabel
} from "@ionic/react";
import { Redirect, useLocation, useParams } from "react-router";
import {
    businessOutline,
    gridOutline,
    documentOutline,
    walletOutline,
    megaphone,
    megaphoneOutline,
    alertCircleOutline
} from "ionicons/icons";
import "./TabsCopropietario.css";
import EscritorioCopropietario from "../EscritorioCopropietario/EscritorioCopropietario";
import InformeCopList from "../InformesCopropietario/InformeCopList";
import InformeCopDetail from "../InformesCopropietario/InformeCopDetail";
import PresupuestosCopList from "../PresupuestosCopropietario/PresupuestosCopList";
import ObrasCopList from "../ObrasCopropietario/ObrasCopList";
import ObraCopDetail from "../ObrasCopropietario/ObraCopDetail";
import InformeCopArchivos from "../InformesCopropietario/InformeCopArchivos";
import InformeCopPresupuestos from "../InformesCopropietario/InformeCopPresupuestos";
import InformeCopNotas from "../InformesCopropietario/InformeCopNotas";
import InformeCopObras from "../InformesCopropietario/InformeCopObras";
import InformeCopMemorias from "../InformesCopropietario/InformeCopMemorias";
import PrivateRoute from "../../routes/PrivateRoute";

import ReportesCopList from "../ReportesCopropietarios/ReportesCopList";
import ObraCopSeguimiento from "../ObrasCopropietario/ObraCopSeguimiento";
import ObraCopVisita from "../ObrasCopropietario/ObraCopVisita";
import ObraCopVisitaObservaciones from "../ObrasCopropietario/ObraCopVisitaObservaciones";
import ReportesCopCreate from "../ReportesCopropietarios/ReportesCopCreate";
import { useSelector } from "react-redux";
import { RootState } from "../../store/ducks/rootReducer";
import ReportesCopDetail from "../ReportesCopropietarios/ReportesCopDetail";
import AvisosCopList from "../AvisosCopropietario/AvisosCopList";
import ModalImagen from "../../components/ModalImagen/ModalImagen";

type TabsCopropietarioProps = {

};

const TabsCopropietario: React.FC<TabsCopropietarioProps> = ({

}) => {
    const { idEdificioCopropietario } = useSelector((state: RootState) => state.users);
    const location = useLocation();

    // Define las rutas donde la barra de tabs no debería aparecer
    const hideTabBar = location.pathname.includes(`/copropietario/edificios/${idEdificioCopropietario}/nuevo/reportes`);
    return (

        <IonTabs>
            <IonRouterOutlet>
                <PrivateRoute
                    exact path="/copropietario"
                    component={() => <Redirect to={`/copropietario/edificios/${idEdificioCopropietario}/escritorio`} />}
                />
                <PrivateRoute
                    exact path="/copropietario/edificios/:idEdificio/escritorio"
                    component={EscritorioCopropietario}
                />
                <PrivateRoute
                    exact path="/copropietario/edificios/:idEdificio/informes"
                    component={InformeCopList}
                />
                <PrivateRoute
                    exact path="/copropietario/edificios/:idEdificio/informes/:informeId"
                    component={InformeCopDetail}
                />
                <PrivateRoute
                    path="/copropietario/edificios/:idEdificio/informes/archivos/:informeId"
                    component={InformeCopArchivos}
                />
                <PrivateRoute
                    path="/copropietario/edificios/:idEdificio/informes/presupuestos/:informeId"
                    component={InformeCopPresupuestos}
                />
                <PrivateRoute
                    path="/copropietario/edificios/:idEdificio/informes/obras/:informeId"
                    component={InformeCopObras}
                />
                <PrivateRoute
                    path="/copropietario/edificios/:idEdificio/informes/notas/:informeId"
                    component={InformeCopNotas}
                />
                <PrivateRoute
                    path="/copropietario/edificios/:idEdificio/informes/memorias/:informeId"
                    component={InformeCopMemorias}
                />
                <PrivateRoute
                    exact path="/copropietario/edificios/:idEdificio/presupuestos"
                    component={PresupuestosCopList}
                />
                <PrivateRoute
                    exact path="/copropietario/edificios/:idEdificio/avisos"
                    component={AvisosCopList}
                />
                <PrivateRoute
                    exact path="/copropietario/edificios/:idEdificio/reportes"
                    component={ReportesCopList}
                />
                <PrivateRoute
                    path="/copropietario/edificios/:idEdificio/nuevo/reportes"
                    component={ReportesCopCreate}
                />
                <PrivateRoute
                    exact path="/copropietario/edificios/:idEdificio/reportes/:reporteId"
                    component={ReportesCopDetail}
                />
                <PrivateRoute
                    exact path="/copropietario/edificios/:idEdificio/obras"
                    component={ObrasCopList}
                />
                <PrivateRoute
                    exact path="/copropietario/edificios/:idEdificio/obras/:obraId"
                    component={ObraCopDetail}
                />
                <PrivateRoute
                    exact path="/copropietario/edificios/:idEdificio/obras/seguimiento/:obraId"
                    component={ObraCopSeguimiento}
                />
                <PrivateRoute
                    exact path="/copropietario/edificios/:idEdificio/obras/seguimiento/:obraId/visitas/:visitaId"
                    component={ObraCopVisita}
                />
                <PrivateRoute
                    exact path="/copropietario/edificios/:idEdificio/obras/seguimiento/:obraId/visitas/:visitaId/observaciones"
                    component={ObraCopVisitaObservaciones}
                />
              
            </IonRouterOutlet>


            <IonTabBar slot="bottom" className={`bottom-tab-det ${hideTabBar ? "hidden-tab-bar" : ""}`}>
                <IonTabButton
                    tab="escritorioCop"
                    href={`/copropietario/edificios/${idEdificioCopropietario}/escritorio`}
                    className="tab-button-det"
                >
                    <IonIcon icon={gridOutline} />
                    <IonLabel>Inicio</IonLabel>

                </IonTabButton>


                <IonTabButton
                    tab="presupuestosCop"
                    href={`/copropietario/edificios/${idEdificioCopropietario}/reportes`}
                    className="tab-button-det"
                >
                    <IonIcon icon={alertCircleOutline} />
                    <IonLabel>Reportes</IonLabel>
                    {/* <IonBadge className="badge-background-det">6</IonBadge> */}
                </IonTabButton>
                <IonTabButton
                    tab="informesCop"
                    href={`/copropietario/edificios/${idEdificioCopropietario}/avisos`}
                    className="tab-button-det"
                >
                    <IonIcon icon={megaphoneOutline} />
                    <IonLabel>Avisos</IonLabel>
                    {/* <IonBadge className="badge-background-det">1</IonBadge> */}
                </IonTabButton>
                <IonTabButton
                    tab="obrasCop"
                    href={`/copropietario/edificios/${idEdificioCopropietario}/obras`}
                    className="tab-button-det"
                >
                    <IonIcon icon={businessOutline} />
                    <IonLabel>Obras</IonLabel>
                    {/* <IonBadge className="badge-background-det">6</IonBadge> */}
                </IonTabButton>

            </IonTabBar>
        </IonTabs>
    );
}
export default TabsCopropietario;


