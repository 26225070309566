import { IEdificio } from "../edificios/types";

/**
 * types
 */
export enum ReporteTypes {
    /*POST REPORTE*/
    POST_REPORTE_REQUEST = "POST_REPORTE_REQUEST",
    POST_REPORTE_SUCCESS = "POST_REPORTE_SUCCESS",
    POST_REPORTE_FAIL = "POST_REPORTE_FAIL",
    /*GET REPORTES BY EDIFICIO*/
    GET_REPORTES_BY_EDIFICIO = "GET_REPORTES_BY_EDIFICIO",
    SET_REPORTES_BY_EDIFICIO = "SET_REPORTES_BY_EDIFICIO",
    SET_REPORTES_BY_EDIFICIO_FAIL = "SET_REPORTES_BY_EDIFICIO_FAIL",
    /*GET REPORTE BY ID*/
    GET_REPORTE_BY_ID_REQUEST = "GET_REPORTE_BY_ID_REQUEST",
    GET_REPORTE_BY_ID_SUCCESS = "GET_REPORTE_BY_ID_SUCCESS",
    GET_REPORTE_BY_ID_FAIL = "GET_REPORTE_BY_ID_FAIL",
    /*DELETE REPORTE BY ID*/
    DELETE_REPORTE_REQUEST = "DELETE_REPORTE_REQUEST",
    DELETE_REPORTE_SUCCESS = "DELETE_REPORTE_SUCCESS",
    DELETE_REPORTE_FAIL = "DELETE_REPORTE_FAIL",
    /*SET REPORTE MESSAGE WITH SCOPE*/
    SET_MESSAGE = "SET_MESSAGE",
    CLEAR_MESSAGE = "CLEAR_MESSAGE",
    /*LOGOUT*/
    LOGOUT = "LOGOUT",
}

/**
 * Data types
 */
export interface IReporte {
    _id?: any;
    expediente?: string; 
    titulo?: string; 
    contenido?: string; 
    estado?: "Pendiente" | "En Progreso" | "Resuelto"; 
    prioridad?: "Alta" | "Media" | "Baja" | "Sin Definir"; 
    unidad?: string; 
    edificio?: string; 
    sector?: string; 
    tipoDesperfecto?: string;
    imgUrl?: string; 
    activo?: boolean;
    evaluacionTecnica?: boolean; 
    vistoPorAdministracion?: boolean;
    tecnicoAsignado?: {
        _id: string; // ID del técnico
        nombre: string; // Nombre del técnico
        apellido: string; // Apellido del técnico
    } | null;
    fechaInicioReparacion?: Date; 
    fechaFinalizacion?: Date; 
    impactoUsoEdificio?: string; 
    createdBy?: string; 
    updatedBy?: string; 
    readBy?: { readerId?: string; readedAt?: Date }[]; 
    createdAt?: Date; 
    updatedAt?: Date; 
}

/**
 * State type
 */
export interface ReporteState {
    reportesLoading: boolean;
    reporteNuevoLoading: boolean;
    reporteByIdLoading: boolean;
    reporteById: IReporte | null;
    reporteByIdFail: boolean;
    reporteNuevo: IReporte | null;
    reportesByEdificio: IReporte[] | [];
    reportesUnread: IReporte[] | [];
    message: string;
    errorMessage: string;
    scope: string | null; // Identifica el contexto del mensaje
}

/**
 * Action types
 */
export interface PostReporteRequestAction {
    type: typeof ReporteTypes.POST_REPORTE_REQUEST;
    payload: { reporte: FormData; idEdificio: any };
}
export interface PostReporteSuccessAction {
    type: typeof ReporteTypes.POST_REPORTE_SUCCESS;
    payload: { message: string; scope: string ; reporte: IReporte};
}
export interface PostReporteFailAction {
    type: typeof ReporteTypes.POST_REPORTE_FAIL;
    payload: { errorMessage: string; scope: string };
}
export interface LogoutAction {
    type: typeof ReporteTypes.LOGOUT;
}
export interface ClearMessageAction {
    type: typeof ReporteTypes.CLEAR_MESSAGE;
}

export interface GetReportesByEdificioAction {
    type: typeof ReporteTypes.GET_REPORTES_BY_EDIFICIO;
    payload: { idEdificio: string };
}

export interface SetReportesByEdificioAction {
    type: typeof ReporteTypes.SET_REPORTES_BY_EDIFICIO;
    payload: {
        reportes: IReporte[];
        message: string;
        scope: string;
    };
}

export interface SetReportesByEdificioFailAction {
    type: typeof ReporteTypes.SET_REPORTES_BY_EDIFICIO_FAIL;
    payload: {
        errorMessage: string;
        scope: string;
    };
}

export interface SetMessageAction {
    type: typeof ReporteTypes.SET_MESSAGE;
    payload: {
        message: string;
        scope: string;
    };
}

export interface GetReporteByIdRequestAction {
    type: typeof ReporteTypes.GET_REPORTE_BY_ID_REQUEST;
    payload: { id: string }; // ID del reporte que se solicita
}

export interface GetReporteByIdSuccessAction {
    type: typeof ReporteTypes.GET_REPORTE_BY_ID_SUCCESS;
    payload: { reporte: IReporte; message: string }; // El reporte obtenido
}

export interface GetReporteByIdFailAction {
    type: typeof ReporteTypes.GET_REPORTE_BY_ID_FAIL;
    payload: { errorMessage: string }; // Error ocurrido durante la solicitud
}
export interface DeleteReporteRequestAction {
    type: typeof ReporteTypes.DELETE_REPORTE_REQUEST;
    payload: { id: string; scope: string }; // ID del reporte a eliminar
}

export interface DeleteReporteSuccessAction {
    type: typeof ReporteTypes.DELETE_REPORTE_SUCCESS;
    payload: { id: string; message: string; scope: string }; // ID eliminado y mensaje de éxito
}

export interface DeleteReporteFailAction {
    type: typeof ReporteTypes.DELETE_REPORTE_FAIL;
    payload: { errorMessage: string; scope: string }; // Mensaje de error
}
export type ReporteActionTypes =
    | PostReporteRequestAction
    | PostReporteSuccessAction
    | PostReporteFailAction
    | GetReportesByEdificioAction
    | SetReportesByEdificioAction
    | SetReportesByEdificioFailAction
    | ClearMessageAction
    | SetMessageAction
    | GetReporteByIdRequestAction
    | GetReporteByIdSuccessAction
    | GetReporteByIdFailAction
    | DeleteReporteRequestAction
    | DeleteReporteSuccessAction
    | DeleteReporteFailAction
    | LogoutAction

